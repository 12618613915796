.main {
  padding: 45px 0px 0px 73px;
  display: flex;
  min-height: 100%;
  gap: 60px;
  flex: 1 0 auto;

  .folderIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 99px;
    height: 99px;
    border-radius: 15.6429px;
    background: rgba($color: #8f529e, $alpha: 0.1);

    img {
      margin: 0 auto;
      padding-top: 27px;
    }
  }

  .first {
    margin-right: 71px;
  }
  .four {
    margin-right: 66px;
  }

  .second {
    margin-right: 61px;
  }

  .third {
    margin-right: 42px;
  }

  .fifth {
    margin-right: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}

.footer {
  flex: 0 0 auto;
  border-top: 1px solid #e5e8eb;

  .saveButton {
    background: #2987cc;
    border-radius: 10px;
    height: 34px;
    width: 122px;
  }
  .saveButton:hover {
    opacity: 0.8;
  }
}
