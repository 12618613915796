.custom-content ul {
    padding-left: 20px;
}

.custom-content h1 {
    text: 32px;
}

.custom-content {
    max-width: 800px;
}

.custom-content ul li {
    margin-bottom: 10px;
    margin-left: 5px;
    list-style-type: disc;
}

.custom-content ul ::marker {
    color: #2987CC;
}

.custom-content ol {
    list-style-type: decimal;
}

.custom-content ol ::marker {
    list-style-type: decimal;
    color: #2987CC;
}

.custom-content ol li {
    margin-bottom: 10px;
    margin-left: 25px;
}

.custom-content table {
    border-collapse: collapse;
    width: 80%;
}

.custom-content td, .custom-content th {
    border: 1px solid rgb(220, 227, 228);
    padding: 8px;
}

.custom-content h3 {
    margin-top: 50px;
    margin-bottom: 10px;
}

.custom-content th {
    background-color: rgb(241, 242, 244);
}

.custom-content pre {
    font-family: 'Courier New', monospace;
    background-color: rgba(9, 30, 66, 0.06);
    padding: 10px;
    border: none;
    overflow-x: auto;
    white-space: pre-wrap;
}

.custom-content a {
    color: #2987CC;
    text-decoration: underline;
    cursor: pointer;
}

.custom-content img {
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.custom-content span {
    color: #2987CC;
}

.custom-content img {
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.custom-content .confluence-information-macro-body {
    background-color: rgb(29, 122, 252) !important;
}

.custom-content .confluence-information-macro-body p {
    margin-left: 10px;
}

