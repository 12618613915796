.App {
  width: 100%;
  height: 100vh;
  background: #1a2128;
  border-left: 3px solid rgba(122, 68, 135, 1);
}

.wavesurfer-handle-start {
  @apply: border-action;
  border: 0.1px solid!important;
  background: transparent;
  opacity: 0;
}
.wavesurfer-handle-end {
  background: none;
  opacity: 0;
}

